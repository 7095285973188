import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import LogoutButton from './LogoutButton';
import UserDisplay from './UserDisplay';
import { useSwipeable } from 'react-swipeable';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

// This is a simplified list of festive days for demonstration purposes
// In a real application, you would want to use a more comprehensive library or API
const festiveDays = {
  'US': {
    '2023-12-25': 'Christmas Day',
    '2023-12-31': 'New Year\'s Eve',
    '2024-01-01': 'New Year\'s Day',
    '2024-07-04': 'Independence Day',
  },
  'UK': {
    '2023-12-25': 'Christmas Day',
    '2023-12-26': 'Boxing Day',
    '2023-12-31': 'New Year\'s Eve',
    '2024-01-01': 'New Year\'s Day',
  },
  // Add more countries as needed
};

const EnhancedCalendar = ({ user, setShowLogin, country = 'US' }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [notes, setNotes] = useState({});
  const [currentNote, setCurrentNote] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      fetchNotes(user);
    } else {
      setNotes({});
    }
  }, [user]);

  const fetchNotes = async (user) => {
    try {
      const { data, error } = await supabase
        .from('notes')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;

      const notesObject = data.reduce((acc, note) => {
        if (!acc[note.date]) {
          acc[note.date] = [];
        }
        acc[note.date].push({ id: note.id, content: note.content });
        return acc;
      }, {});

      setNotes(notesObject);
    } catch (error) {
      console.error('Error fetching notes:', error);
      setError('Failed to fetch notes. Please try again.');
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    return d.toISOString().split('T')[0];
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNextMonth,
    onSwipedRight: handlePrevMonth,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setCurrentNote('');
    setError(null);
  };

  const handleAddNote = async () => {
    if (selectedDate && currentNote.trim() !== '' && user) {
      const dateKey = formatDate(selectedDate);
      try {
        const { data, error } = await supabase
          .from('notes')
          .insert({ user_id: user.id, date: dateKey, content: currentNote })
          .select();

        if (error) throw error;

        setNotes(prevNotes => ({
          ...prevNotes,
          [dateKey]: [...(prevNotes[dateKey] || []), { id: data[0].id, content: currentNote }]
        }));
        setCurrentNote('');
        setError(null);
      } catch (error) {
        console.error('Error adding note:', error);
        setError('Failed to add note. Please try again.');
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddNote();
    }
  };

  const handleDeleteNote = async (dateKey, noteId) => {
    if (user) {
      try {
        const { error } = await supabase
          .from('notes')
          .delete()
          .eq('id', noteId)
          .eq('user_id', user.id);

        if (error) throw error;

        setNotes(prevNotes => {
          const updatedNotes = { ...prevNotes };
          updatedNotes[dateKey] = updatedNotes[dateKey].filter(note => note.id !== noteId);
          if (updatedNotes[dateKey].length === 0) {
            delete updatedNotes[dateKey];
          }
          return updatedNotes;
        });
        setError(null);
      } catch (error) {
        console.error('Error deleting note:', error);
        setError('Failed to delete note. Please try again.');
      }
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    }
  };

  const getDaysArray = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const days = [];

    // Previous month's days
    const daysToAdd = (firstDay.getDay() + 6) % 7; // Adjust for Monday start
    for (let i = daysToAdd; i > 0; i--) {
      const prevMonthDay = new Date(year, month, -i + 1);
      days.push({ date: prevMonthDay, isCurrentMonth: false });
    }

    // Current month's days
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push({ date: new Date(year, month, i), isCurrentMonth: true });
    }

    // Next month's days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push({ date: new Date(year, month + 1, i), isCurrentMonth: false });
    }

    return days;
  };

  const isFestiveDay = (date) => {
    const formattedDate = formatDate(date);
    return festiveDays[country] && festiveDays[country][formattedDate];
  };

  const today = new Date();
  const daysArray = getDaysArray();

  return (
    <div className="w-full max-w-md mx-auto">
      <div className="flex justify-between items-center mb-4">
        {user && <UserDisplay user={user} />}
        {user && <LogoutButton onLogout={handleLogout} />}
      </div>
      <div {...handlers} className="touch-pan-y">
        <div className="flex justify-between items-center mb-4">
          <button onClick={handlePrevMonth} className="text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1">
            ←
          </button>
          <h2 className="text-xl font-light">
            {currentDate.toLocaleString('en-US', { month: 'long', year: 'numeric' })}
          </h2>
          <button onClick={handleNextMonth} className="text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded-full p-1">
            →
          </button>
        </div>
        <div className="grid grid-cols-7 gap-1 mb-4">
          {daysOfWeek.map(day => (
            <div key={day} className="text-center font-light text-gray-500">
              {day}
            </div>
          ))}
          {daysArray.map(({ date, isCurrentMonth }) => {
            const dateKey = formatDate(date);
            const isSelected = selectedDate && formatDate(selectedDate) === dateKey;
            const hasNotes = notes[dateKey] && notes[dateKey].length > 0;
            const isToday = date.getDate() === today.getDate() && 
                            date.getMonth() === today.getMonth() && 
                            date.getFullYear() === today.getFullYear();
            const isFestive = isFestiveDay(date);
            return (
              <button
                key={dateKey}
                onClick={() => handleDateClick(date)}
                className={`text-center p-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300
                  ${isSelected ? 'bg-gray-200' : ''}
                  ${hasNotes ? 'font-bold' : ''}
                  ${isToday ? 'bg-gray-100 ring-2 ring-gray-300' : ''}
                  ${!isCurrentMonth ? 'text-gray-300' : ''}
                  ${isFestive ? 'underline decoration-2 decoration-gray-400' : ''}
                `}
                title={isFestive ? festiveDays[country][dateKey] : ''}
              >
                {date.getDate()}
              </button>
            );
          })}
        </div>
      </div>
      {!user && (
        <div className="mt-4 text-center">
          <button
            onClick={() => setShowLogin(true)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-light py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50"
          >
            Wanna take notes? Log in
          </button>
        </div>
      )}
      {selectedDate && user && (
        <div className="mt-4">
          <h3 className="text-lg font-light mb-2">
            Notes for {selectedDate.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </h3>
          <div className="flex mb-2">
            <input
              type="text"
              value={currentNote}
              onChange={(e) => setCurrentNote(e.target.value)}
              onKeyPress={handleKeyPress}
              className="flex-grow p-2 border rounded-l-md font-light focus:outline-none focus:ring-2 focus:ring-gray-300"
              placeholder="Enter a note..."
            />
            <button
              onClick={handleAddNote}
              className="bg-gray-200 text-gray-800 px-4 rounded-r-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Add
            </button>
          </div>
          {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
          <ul className="space-y-2">
            {notes[formatDate(selectedDate)]?.map((note) => (
              <li key={note.id} className="flex justify-between items-center bg-gray-100 p-2 rounded">
                <span>{note.content}</span>
                <button
                  onClick={() => handleDeleteNote(formatDate(selectedDate), note.id)}
                  className="text-red-500 hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 rounded-full p-1"
                >
                  ×
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EnhancedCalendar;